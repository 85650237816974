import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueFlags from './plugins/vue-flags';
import router from './router'

Vue.config.productionTip = false

/*
new Vue({
  vuetify,
  router: router,
  data: () => ({
    sidebarMenu: true,
    toggleMini: false,
    items: [
      { title:"Home", href:"/", icon:"mdi-home-outline" },
      { title:"Detections", href:"/detections", icon:"mdi-shield-account" },
      { title:"Components", href:"/comp", icon:"mdi-palette-swatch" },
      { title:"Customers", href:"/customers", icon:"mdi-account-search-outline" },
      { title:"Orders", href:"/orders", icon:"mdi-bus-clock" },
      { title:"Settings", href:"/settings", icon:"mdi-settings-outline" },
    ],
  }),
  methods: {
    toggleTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;      
    },
  },
//  render: h => h(App)
}).$mount('#app')
*/

new Vue({
  vuetify, 
  VueFlags,
  router,
  render: h => h(App)
}).$mount('#app')
