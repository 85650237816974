<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Unsere nächsten Kurse
        </h1>

        <p class="subheading font-weight-regular">Jetzt gleich online buchen und Sitzplatz sichern!</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!--
        <v-btn v-on:click="fetchData()">Update Liste</v-btn>
        -->

      <div v-if="loading" class="loading">
        Loading...
      </div>

      <div v-if="error" class="error">
        {{ error }}
      </div>

      <div v-if="courses" class="content">


        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Wochentag</th>
                <th class="text-left">Datum</th>
                <th class="text-left">Uhrzeit</th>
                <th class="text-left">Filiale</th>
                <th class="text-left">Preis</th>
                <th class="text-left">Sprache</th>
                <th class="text-left">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in courses" :key="item.id">
                <td class="text-left">{{ item.name }}</td>
                <td class="text-left">{{ item.datum }}</td>
                <td class="text-left">{{ item.zeit_von }} - {{ item.zeit_bis }}</td>
                <td class="text-left">{{ filialen.get(item.filialen_id) }}</td>
                <td class="text-left">{{ item.preis }},- Euro</td>
                <td class="text-left"><gb-flag :code="item.sprache? item.sprache : ''" size="small" /></td>
                <td class="text-left"><v-btn color="success" :to="{ name: 'BookingNew', params: { courseId: item.id } }">Kurs buchen</v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import jsonFilialen from "@/assets/data/filialen.json";

export default {
  name: "CourseList",

  data: () => ({
    loading: false,
    error: null,
    nSkip: 0,
    filialen: new Map(jsonFilialen),
    courses: [],
  }),
  methods: {
    fetchData() {
      //   fetch("weather.json")
      //     .then(response => response.json())
      //     .then(data => (this.weatherDataList = data));
      // TODO: remove switchcase
      let target = (process.env.NODE_ENV === 'development')? "http://localhost:3000/api/kurse?skip=" : "/api/kurse?skip="
//      let target = (process.env.NODE_ENV === 'development')? "/data/kurse.json?skip=" : "/api/kurse?skip="
//      axios.get("/api/kurse?skip=" + this.nSkip)
      axios.get(target + this.nSkip)
      .then(response => {this.courses = response.data; this.loading = false; })
      .catch(error => {this.error = error; this.loading = false; });
    }
  },
  created(){
    this.fetchData();
  },
};
</script>
