<template>
  <div class="home">
<!--    <img alt="Vue logo" src="../assets/logo.png"> -->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <CourseList/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import CourseList from '@/components/CourseList.vue'

export default {
  name: 'Home',
  components: {
//    HelloWorld
    CourseList
  }
}
</script>
